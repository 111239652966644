import * as React from 'react';
import { useTheme } from '@mui/material/styles';
import { Routes } from '../../util/constants'
import { useTranslation } from 'react-i18next';
import { topNavBarHeight, leftBarWidth, leftBarSeperatorColor } from "./constants";

import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileInvoiceDollar, faFileSignature, faFileLines, faFilePen, faIndustry } from '@fortawesome/free-solid-svg-icons';

import HomeIcon from '@mui/icons-material/Home';
import PeopleIcon from '@mui/icons-material/People';
import CategoryIcon from '@mui/icons-material/Category';
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';
import EqualizerIcon from '@mui/icons-material/Equalizer';
import SettingsIcon from '@mui/icons-material/Settings';
import QuestionMarkIcon from '@mui/icons-material/QuestionMark';
import hexToRGBA from '../../util/converters/hexToRGBA';
import Tooltip from '@mui/material/Tooltip';
import SearchIcon from '@mui/icons-material/Search';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import TagIcon from '@mui/icons-material/Tag';
import { Menu, MenuItem, Modal, Typography } from '@mui/material';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from '../../state';
import { openCustomerPopupModal } from '../../state/customerPopupModalSlice';
import { openSettingsModal } from '../../state/settingsModalSlice';
import { openCustomerTermsModal } from '../../state/customerTermsModalSlice';
import { openVendorTermsModal } from '../../state/vendorTermsModalSlice';
import { openShipMethodsModal } from '../../state/shipMethodsModalSlice';
import { openSystemNumbersModal } from '../../state/systemNumbersModalSlice';
import { openVendorPopupModal } from '../../state/vendorPopupModalSlice';


const leftBarOptionHeith = 56;
const MenuItemHeight = 44

// Options Bar Icons
const OptionsMUIIconSize = 34;
const OptionsFAIconSize = 29;

// Options Menu Icons
const OptionsMenuFAIconSize = 21;

// Interface for menu items
interface MenuOption {
    text: string;
    icon: React.ReactElement;
    onClick?: () => void; // Pass any function you wnat to run what ever code you want on click.
}

// Interface for navigation bar options
interface NavBarOption {
    text: string;
    icon: React.ReactElement;
    menu: MenuOption[];
}

export default function LeftNavBar({
    children
}: {
    children: React.ReactNode;
}): JSX.Element {
    const theme = useTheme();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { t } = useTranslation([], { keyPrefix: "team.PageLayout.LB" })

    const [anchorElNew, setAnchorElNew] = useState<null | HTMLElement>(null);
    const [currentMenu, setCurrentMenu] = useState<string | null>(null);

    const navBarOptions: NavBarOption[] = [
        {
            text: t("Dashboard"), icon: <HomeIcon onClick={() => navigate(Routes.HOME)} style={{ fontSize: OptionsMUIIconSize }} />, menu: [],
        },
        {
            text: t("Customers"), icon: <PeopleIcon style={{ fontSize: OptionsMUIIconSize }} />,
            menu: [
                { text: t("Menu.Customers.Add Customer"), icon: <PersonAddIcon />, onClick: () => dispatch(openCustomerPopupModal()) },
                { text: t("Menu.Customers.Browse Customers"), icon: <SearchIcon />, onClick: () => { navigate(Routes.HOME_CUSTOMERS) } }
            ]
        },
        {
            text: t("Vendors"), icon: <FontAwesomeIcon icon={faIndustry} style={{ fontSize: OptionsFAIconSize }} />,
            menu: [
                { text: t("Menu.Vendors.Add Vendor"), icon: <PersonAddIcon />, onClick: () => dispatch(openVendorPopupModal()) },
                { text: t("Menu.Vendors.Browse Vendors"), icon: <SearchIcon />, onClick: () => { navigate(Routes.HOME_VENDORS) } }
            ]
        },
        { text: t("Items"), icon: <CategoryIcon style={{ fontSize: OptionsMUIIconSize }} />,
            menu: [
                {text: t("Menu.Items.Browse Items"), icon: <SearchIcon/>, onClick: () => { navigate(Routes.HOME_ITEMS)}}
        ] },
        { text: t("Orders"), icon: <FontAwesomeIcon icon={faFileSignature} style={{ fontSize: OptionsFAIconSize }} />, menu: [] },
        { text: t("Invoices"), icon: <FontAwesomeIcon icon={faFileLines} style={{ fontSize: OptionsFAIconSize }} />, menu: [] },
        { text: t("Packing Slips"), icon: <FontAwesomeIcon icon={faFilePen} style={{ fontSize: OptionsFAIconSize }} />, menu: [] },
        { text: t("Finance"), icon: <MonetizationOnIcon style={{ fontSize: OptionsMUIIconSize }} />, menu: [] },
        { text: t("Estimates"), icon: <FontAwesomeIcon icon={faFileInvoiceDollar} style={{ fontSize: OptionsFAIconSize }} />, menu: [] },
        { text: t("Reports"), icon: <EqualizerIcon style={{ fontSize: OptionsMUIIconSize }} />, menu: [] },
        {
            text: t("Utilities"), icon: <SettingsIcon style={{ fontSize: OptionsMUIIconSize }} />, menu: [
                { text: t("Menu.Utilities.Settings"), icon: <SettingsIcon />, onClick: () => dispatch(openSettingsModal()) },
                { text: t("Menu.Utilities.Customer Terms"), icon: <PeopleIcon />, onClick: () => dispatch(openCustomerTermsModal()) },
                { text: t("Menu.Utilities.Vendor Terms"), icon: <FontAwesomeIcon icon={faIndustry} style={{ fontSize: OptionsMenuFAIconSize }} />, onClick: () => dispatch(openVendorTermsModal()) },
                { text: t("Menu.Utilities.Shipping Methods"), icon: <LocalShippingIcon />, onClick: () => dispatch(openShipMethodsModal()) },
                { text: t("Menu.Utilities.System Numbers"), icon: <TagIcon />, onClick: () => dispatch(openSystemNumbersModal()) },
            ]
        },
        { text: t("Help"), icon: <QuestionMarkIcon style={{ fontSize: OptionsMUIIconSize }} />, menu: [] },
    ];

    const handleOpenNewMenu = (event: React.MouseEvent<HTMLElement>, menuKey: string) => {
        setAnchorElNew(event.currentTarget);
        setCurrentMenu(menuKey);
    };

    const handleCloseNewMenu = () => {
        setAnchorElNew(null);
        setCurrentMenu(null);
    };


    const options = (
        <div>
            <List sx={{ p: 0 }}>
                {navBarOptions.map((option, i) => (
                    <div key={`${option.text}-${i}`}>
                        <ListItem key={option.text} disablePadding sx={{ height: leftBarOptionHeith }} >
                            <Tooltip title={option.text} placement="right">
                                <ListItemButton sx={{
                                    padding: 0, height: "100%", "&:hover": {
                                        backgroundColor: hexToRGBA(theme.palette.primary.main, 0.08),
                                        borderRight: `4px solid ${theme.palette.primary.main}`,
                                    }

                                }}
                                    onClick={(event) => handleOpenNewMenu(event, option.text)}
                                >
                                    <ListItemIcon sx={{ margin: "auto" }}>
                                        {React.cloneElement(option.icon, { style: { margin: "auto", color: theme.palette.primary.main, ...option.icon.props.style } })}
                                    </ListItemIcon>
                                </ListItemButton>
                            </Tooltip>
                        </ListItem>
                        <Divider />
                        {option.menu.length != 0 && (
                            <Menu
                                MenuListProps={{ sx: { paddingTop: 0, paddingBottom: 0 } }}
                                sx={{ ml: `${leftBarWidth - 5}px` }}
                                anchorEl={anchorElNew}
                                anchorOrigin={{
                                    vertical: "top",
                                    horizontal: "right",
                                }}
                                keepMounted
                                transformOrigin={{
                                    vertical: "top",
                                    horizontal: "right",
                                }}
                                open={currentMenu === option.text}
                                onClose={handleCloseNewMenu}
                            >
                                {option.menu.map((menuItem, i) => (
                                    <MenuItem divider key={i} sx={{ paddingY: 0, height: MenuItemHeight, width: "220px" }} onClick={() => {
                                        if (menuItem.onClick) {
                                            menuItem.onClick();
                                        }
                                        handleCloseNewMenu()
                                    }} disableGutters>
                                        <ListItemIcon>
                                            {React.cloneElement(menuItem.icon, { style: { margin: "auto", color: theme.palette.primary.main, ...menuItem.icon.props.style } })}
                                        </ListItemIcon>
                                        <Typography textAlign="center" color="primary">{menuItem.text}</Typography>
                                    </MenuItem>
                                ))}
                            </Menu>
                        )}
                    </div>
                ))}
            </List>
        </div>
    );

    return (
        <Box sx={{ display: "flex", height: `calc(100vh - ${topNavBarHeight})`, flex: 1 }}>
            <div style={{ display: "flex", flexDirection: "row", width: "100%" }}>
                <Box
                    component="nav"
                    sx={{ width: { sm: leftBarWidth }, flexShrink: { sm: 0 }, overflowY: "auto", overflowX: "hidden", height: "100%", borderRight: `1px solid ${leftBarSeperatorColor}` }}
                    aria-label="mailbox folders"
                >
                    <Box
                        sx={{
                            display: { width: leftBarWidth },
                        }}
                    >
                        {options}
                    </Box>
                </Box>
                <Box
                    component="main"
                    sx={{ p: 0, width: "100%", maxHeight: `calc(100vh - ${topNavBarHeight})`, overflowY: "auto" }}
                >
                    {/* We render children on the right side of the nav bar */}
                    {children}
                </Box>
            </div>
        </Box>
    );
}
