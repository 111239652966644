import "immer"
import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { Customer } from "orderflow-lambdas"

export interface AltShipMethodsModalState {
    openAltShipMethodsModal: boolean
    customer?: Customer,
    loading: boolean
}

// Initialize state
const initialState: AltShipMethodsModalState = {
    openAltShipMethodsModal: false,
    loading: false
}

export const altShipMethodsModalSlice = createSlice({
    name: "altShipMethodsModal",
    initialState,
    reducers: {
        openAltShipMethodsModal: (state,action: PayloadAction<{
            customer: Customer}>) => ({
            ...state,
            openAltShipMethodsModal: true,
            customer: action.payload.customer,
        }),
        closeModal: () => ({
            ...initialState
        }),
        setLoading: (state, action: PayloadAction<boolean>) => ({
            ...state,
            loading: action.payload,
        }),
    },
})

export const {
    openAltShipMethodsModal,
    closeModal,
    setLoading
} = altShipMethodsModalSlice.actions
export default altShipMethodsModalSlice.reducer
