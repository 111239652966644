import * as React from 'react';
import { Menu, MenuItem, IconButton, Stack } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import PersonIcon from '@mui/icons-material/Person';
import HistoryIcon from '@mui/icons-material/History';
import DeleteIcon from '@mui/icons-material/Delete';
import ListIcon from '@mui/icons-material/List';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import { useDispatch } from '../../state';
import { openDeleteCustomerModal, openEditCustomerModal } from "../../state/customerModalSlice";
import { Customer } from 'orderflow-lambdas';
import { faTruckFast } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { openAltShipMethodsModal } from '../../state/altShipMethodsModalSlice';

const OptionsMenuFAIconSize = 21;
const OptionsMUIIconSize = 30;


interface BasicMenuProps {
  customer: Customer
}

export default function BasicMenu(props: BasicMenuProps) {
  // const {searchInput, searchBy} = useSelector((store) => store.customerBrowseSlice)
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const dispatch = useDispatch();
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);

  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleEditCustomer = () => {
    dispatch(openEditCustomerModal({
      customerIdToEdit: props.customer.CustomerId,
      updateCallback: async (customer) => {
        if (customer) {
          // Here you can handle updates with props.customer and user
        }
      },
    }));
    setAnchorEl(null);
  };

  const handleDeleteCustomer = () => {
    setAnchorEl(null);
    dispatch(openDeleteCustomerModal({
      customerToDelete: props.customer,
      // it takes too long for changes to propogate after deleting a customer

      // deleteCallback: async () => {
      //   const payload:CustomerSearchInput = { query: searchInput, searchBy, size: 100, page: 0}
      //   dispatch(searchCustomers(payload))
      // }
    }))
  }

  const handleAlternateShippingMethods = () => {
    setAnchorEl(null);
    dispatch(openAltShipMethodsModal({
      customer: props.customer,
    }))
  }
  return (
    <div>
      <IconButton
        aria-controls={open ? 'basic-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
        className='text-2xl tracking-tight border-0 rounded-full'
      >
        <MoreHorizIcon />
      </IconButton>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        <MenuItem onClick={handleEditCustomer} style={{ color: 'rgb(51, 99, 255)' }}>
          <div className="w-8 h-[30px] flex items-center justify-center">
            <EditIcon style={{ fontSize: OptionsMUIIconSize }} />
          </div>
          &nbsp; Edit Customer
        </MenuItem>
        <MenuItem onClick={handleClose} style={{ color: 'rgb(51, 99, 255)' }}>
          <div className="w-8 h-[30px] flex items-center justify-center">
            <ListIcon style={{ fontSize: OptionsMUIIconSize }} />
          </div>
          &nbsp; View Order History
        </MenuItem>
        <MenuItem onClick={handleClose} style={{ color: 'rgb(51, 99, 255)' }}>
          <div className="w-8 h-[30px] flex items-center justify-center">
            <PersonIcon style={{ fontSize: OptionsMUIIconSize }} />
          </div>
          &nbsp; View Customer
        </MenuItem>
        <MenuItem onClick={handleClose} style={{ color: 'rgb(51, 99, 255)' }}>
          <div className="w-8 h-[30px] flex items-center justify-center">
            <HistoryIcon style={{ fontSize: OptionsMUIIconSize }} />
          </div>
          &nbsp; View Timeline
        </MenuItem>
        <MenuItem onClick={handleAlternateShippingMethods} style={{ color: 'rgb(51, 99, 255)' }}>
          <div className="w-8 h-[30px] flex items-center justify-center">
            <FontAwesomeIcon icon={faTruckFast} style={{ fontSize: OptionsMenuFAIconSize }} />
          </div>
          &nbsp; Alternate Shipping Methods
        </MenuItem>
        <MenuItem onClick={handleDeleteCustomer} style={{ color: 'rgb(51, 99, 255)' }}>
          <div className="w-8 h-[30px] flex items-center justify-center">
            <DeleteIcon style={{ fontSize: OptionsMUIIconSize }} />
          </div>&nbsp; Delete
        </MenuItem>
      </Menu>
    </div>
  );
}